<template>
<div id="page-plantillas-precios">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Plantillas de Precios" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                <template slot="fecha" slot-scope="props">
                  {{ getFechaUsuario(props.row.fecha) }}
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="45%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nueva" }} Plantilla</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
            <v-row>
              <v-col>
                <v-text-field v-if="update==false" label="Nombre" v-model="model.nombre" :rules="[rules.required]" maxlength="40"></v-text-field>
                <v-text-field v-else disabled label="Nombre" v-model="model.nombre" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="9" md="9" lg="9">
                <v-autocomplete v-model="model.columnas" :items="numero_columnas" :hide-no-data="true"
                :hide-selected="true" item-text="cantidad" item-value="cantidad"
                 label="Cantidad de Columnas" :rules="[rules.required]" persistent-hint
                 hint="Es la cantidad de precios o articulos por fila que se plasmarán en la plantila de word"
                 >
                </v-autocomplete>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                    <br>
                    <v-btn x-small dark color="info" @click.native="modalEjemplo=true"
                        title="Ejemplos" v-tippy>Ver Ejemplos
                    </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input label="Seleccione un archivo"
                        :rules="update==false?[rules.required]:[]"
                        v-model="file_adjunto"
                        :show-size="1000"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ></v-file-input>
              </v-col>
            </v-row>
            <v-row v-show="(model_adjunto!=null && model_adjunto!='')">
                <v-col>
                    Archivo: {{ model_adjunto.nombre }} &nbsp;&nbsp;
                    <v-btn x-small fab dark color="success" @click.native="descargarAdjunto(model_adjunto)"
                            title="Descargar Adjunto" v-tippy>
                            <v-icon>file_download</v-icon>
                        </v-btn>
                </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-autocomplete v-model="model.tipo_plantilla" :items="tipos_plantillas" :hide-no-data="true"
                :hide-selected="true" item-text="tipo" item-value="tipo"
                 label="Tipo de Plantilla" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
              <v-col sm="8" md="8" lg="8" v-show="['Precios','Codigos'].includes(model.tipo_plantilla)">
                    <v-autocomplete v-model="model.id_articulo" :items="articulos" :hide-no-data="true"
                    :loading="loadingArticulos" ref="selectarticulo" placeholder="Ingrese nombre de artículo"
                    prepend-icon="search" clearable item-text="nombre" item-value="_id"
                    label="Artículo">
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
              <v-col sm="2" md="2" lg="2">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog v-model="modalEjemplo" max-width="55%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Ejemplo de Plantillas </strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalEjemplo = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>

          <v-container grid-list-md>
            <v-row>
              <v-col>
                <strong>1 Columna:</strong>
                <img :src="'../static/imagenes/1columna.png'" width="100%">
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <strong>2 Columnas:</strong>
                <img :src="'../static/imagenes/2columnas.png'" width="100%" >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <strong>2 Columnas Información Nutrimental:</strong>
                <img :src="'../static/imagenes/2columnas_informacion.png'" width="100%" >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <strong>3 Columnas:</strong>
                <img :src="'../static/imagenes/3columnas.png'" width="100%" >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <strong>4 Columnas:</strong>
                <img :src="'../static/imagenes/4columnas.png'" width="100%" >
              </v-col>
            </v-row>


          </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalEjemplo = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.precios.plantillas')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
    this.model.usuario = this.$local_storage.get("sb_usuario");
    this.getArticulos();
  },
  data() {
    return {
      articulos:[],
      loadingArticulos:false,
      modalEjemplo:false,
      file_adjunto:null,
      model_adjuntos_rev:"",
      model_adjunto: "",
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      numero_columnas: [
          {"cantidad":1},{"cantidad":2},{"cantidad":3},{"cantidad":4},{"cantidad":5},
          {"cantidad":6},{"cantidad":7},{"cantidad":8},{"cantidad":9},{"cantidad":10},
          {"cantidad":11},{"cantidad":12},{"cantidad":13},{"cantidad":14},{"cantidad":15},
          {"cantidad":16},{"cantidad":17},{"cantidad":18},{"cantidad":19},{"cantidad":20},
          ],
      fab: [],
      name: "datagrid",
      fechaUsuario: "",
      columns: [
        "nombre", "tipo_plantilla","fecha", "usuario", "columnas", "estatus",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["nombre", "tipo_plantilla", "usuario", "columnas", "estatus"],
        sortable: ["nombre", "tipo_plantilla", "fecha", "usuario", "columnas", "estatus"],

        headings: {
          nombre: "Nombre",
          "fecha": "Fecha",
          "usuario": "Usuario",
          "columnas": "# Columnas",
          estatus: "Estatus",
          actions: "Operaciones"
        },
        listColumns: {
            estatus: [{
                    id: 'Activo',
                    text: "Activo"
                },
                {
                    id: 'Inactivo',
                    text: "Inactivo"
                }
            ]
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Precios",
          disabled: true,
          href: ""
        },
        {
          text: "Plantillas de Precios",
          href: ""
        }
      ],

      modal: false,

      update: false,
      tipos_plantillas: [
        {"tipo":"Precios"},
        {"tipo":"Codigos"},
        {"tipo":"Sello Factura"},
        {"tipo":"Caratula Folio"},
      ],

      model: {
        _id: "",
        _rev: "",
        type: "plantillas",
        nombre: "",
        fecha: "",
        estatus: "Activo",
        columnas: 1,
        usuario: "",
        tipo_plantilla:"Precios", //Precios, Codigos, Sello Factura, Caratula Folio
        id_articulo:""
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    onStatusChanged(event) {
      this.registros.items = []; 
      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"plantillas"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            //sort: ["fecha"],
          },
        }).then(response => {
          if (response.data.docs.length) {
            response.data.docs.sort(function (a, b) {
                //Ordenar por fecha más reciente
                return new Date(b.fecha) - new Date(a.fecha);
            });
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getArticulos:function(){
          this.loadingArticulos = true;
          let data = {
              "selector": {
                  "type": "articulos",
                  "estatus":"Activo",                  
              },
              "fields":["_id","nombre"],
              "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
          };
          window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
              .then(response => {
                  response.data.docs.sort(this.ordenar_nombre);
                  this.articulos = response.data.docs;
              })
              .catch(error => {
                  this.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al obtener los articulos.",
                      footer: ""
                  });
              }).then(() => {
                  this.loadingArticulos = false;
              });
      },
    getImagen(imagen){
        return '../assets/logo.png';
    },
    openModal: function(type) {
      if (type === 1) {
        this.file_adjunto = null;
        this.model_adjunto = "";
        this.update = false;
        this.model._id = "";
        this.model.nombre = "";
        this.model.estatus = "Activo";
        this.model.columnas = 1;
        this.model.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
        this.model.id_articulo = "";
      } else {
        this.update = true;
      }

      this.modal = true;
    },

    validaUnicos: async function(){
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
          await self.validaNombre().then(result => {
          }).catch(err => {
            duplicados.push("Nombre");
          });

          if(duplicados.length > 0)
            return reject(duplicados);
          else
            return resolve(true);

      });

    },

    getFechaUsuario: function(fecha){
        return window.moment(fecha).format("DD-MM-YYYY");
    },

    validaNombre: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.nombre != null && self.model.nombre!="") {
            var busca = encodeURI(escape(self.model.nombre.toString().trim().toLowerCase()));
            window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/plantillas/_view/nombre?key=\"'+busca+'\"')
              .then(response => {

                  if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                    if(self.model._id != ""){//Esta editando
                      var filter = response.data.rows.find( r => {
                          return self.model._id != r.id;
                      });
                      if(filter){
                        return reject(false);
                      }
                    } else { //Es nuevo
                        return reject(false);
                    }
                  }
                    return resolve(true);
              })
              .catch(err => {
                  return reject(false);
              });
          } else
            return resolve(true);
      });
    },

    getRegistro: function(id_registro) {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
            .then(response => {
                this.model = response.data;
                this.file_adjunto = null;
                this.model_adjunto = "";
                self.model_adjuntos_rev = response.data._rev;
                //CONSULTA: traer archivos adjuntos
                if(response.data._attachments != null)
                    for(var key in response.data._attachments){
                        console.log(key);
                        self.model_adjunto = {"nombre":key, "_id":response.data._id, "_rev":response.data._rev};
                    }
                this.openModal(2);
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
    },
    saveRegistro: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then( result => {
            let data = {
              type: this.model.type,
              nombre: this.model.nombre.toString().trim(),
              fecha: this.model.fecha,
              estatus: this.model.estatus,
              tipo_plantilla: this.model.tipo_plantilla,
              id_articulo: this.model.id_articulo,
              columnas: this.model.columnas,
              usuario: this.$local_storage.get("sb_usuario"),
            };

            window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
              .then(response => {
                this.modal = false;
                window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                  color: 'success'
                });
                this.model._id = response.data.id;
                this.model._rev = response.data.rev;
                this.model_adjuntos_rev = response.data.rev;
                this.adjuntarArchivo();
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
              })
              .catch(error => {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al guardar el registro.",
                  footer: ""
                });
              }).then(() => {
                window.dialogLoader.hide();
              });
          }).catch( err => {
            window.dialogLoader.hide();
            var mensaje = "";
            if(err.length > 1)
              mensaje = err.join(', ')+" ya existen. Favor de verificar.";
            else
              mensaje = err.join(', ')+" ya existe. Favor de verificar.";

            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: mensaje,
              footer: ""
            });
        });
      }
    },
    updateRegistro: function() {

      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
          this.validaUnicos().then( result => {
            this.model.usuario = this.$local_storage.get("sb_usuario");
            let data = this.model;

            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
              .then(response => {
                window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                  color: 'success'
                });
                this.model._id = response.data.id;
                this.model._rev = response.data.rev;
                this.model_adjuntos_rev = response.data.rev;
                if (this.file_adjunto!=null)
                    this.adjuntarArchivo();
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
              })
              .catch(error => {
                window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                  color: 'error'
                });
              }).then(() => {
                this.modal = false;
                window.dialogLoader.hide();
              });
          }).catch( err => {

            window.dialogLoader.hide();
            var mensaje = "";
            if(err.length > 1)
              mensaje = err.join(', ')+" ya existen. Favor de verificar.";
            else
              mensaje = err.join(', ')+" ya existe. Favor de verificar.";

            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: mensaje,
              footer: ""
            });
        });
      }
    },
    adjuntarArchivo: function(){

        window.dialogLoader.show('Espere un momento por favor..');

        var name = encodeURIComponent(this.model.nombre+".docx");
        var id_model = this.model._id;
        var rev = this.model_adjuntos_rev;

        var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +id_model+'/' + name;
        if(rev != ""){ //Si trae rev es que ya se habían adjuntado archivos antes
            url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +id_model+'/' + name+"?rev="+rev;
        }

        var self = this;
        window.axios
        .put(url, self.file_adjunto, {headers:{'Content-Type':self.file_adjunto.type}})
        .then(response => {
            window.dialogLoader.showSnackbar('Archivo guardado correctamente', {
                color: 'success'
            });
        })
        .catch(error => {
            window.dialogLoader.hide();
            console.log("ERROR ", error);
            self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al adjuntar el archivo. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            self.file_adjunto = null;
        });

    },

    descargarAdjunto: function(row){
        var name = encodeURIComponent(row.nombre);

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +row._id+'/'+name+"?rev="+row._rev;
        a.target = "_blank";
        a.click();
        a.parentNode.removeChild(a);
    },
    eliminarAdjunto: function(row){
        window.dialogLoader.show('Espere un momento por favor..');
        var name = encodeURIComponent(row.nombre);
        var self = this;

        window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +row._id+'/'+name+"?rev="+row._rev
            ).then(response => {
                window.dialogLoader.showSnackbar('Archivo eliminado correctamente', {
                    color: 'success'
                });
            })
            .catch(error => {
                self.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al eliminar el archivo. Intente nuevamente.",
                        footer: ""
                    });
            }).then(()=>{
                window.dialogLoader.hide();
            });
    },
    deleteRegistro: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });

            }).then(()=>{
              window.dialogLoader.hide();
            });

        }

      });
    },

  }
};
</script>
